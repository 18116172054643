import { useEffect } from 'react'

declare var SpatialNavigation: any
const spatialNavigationCdn =
  'https://luke-chang.github.io/js-spatial-navigation/spatial_navigation.js'

const baseOptions = {
  straightOverlapThreshold: 0,
  rememberSource: true
}

const initSpatialNavigation = () => {
  SpatialNavigation.init()
  SpatialNavigation.add('main-menu', {
    selector: '.focusable-main-menu',
    ...baseOptions
  })

  SpatialNavigation.add('todo-pending', {
    selector: '.focusable-todo-pending',
    ...baseOptions
  })

  SpatialNavigation.add('todo-completed', {
    selector: '.focusable-todo-completed',
    ...baseOptions
  })

  SpatialNavigation.add('wellness-menu', {
    selector: '.focusable-wellness-menu',
    ...baseOptions
  })

  SpatialNavigation.add('global', {
    selector: 'input, select, button, a, .DayPicker-Day',
    ...baseOptions
  })

  SpatialNavigation.makeFocusable()
  SpatialNavigation.focus()
  console.log('Spatial Navigation')
}

const addScript = (onload: () => void) => {
  const id = 'spatial-navigation'
  if (!document.getElementById(id)) {
    const script = document.createElement('script')
    script.src = spatialNavigationCdn
    script.async = true
    script.id = id
    document.head.appendChild(script)
    script.onload = onload
  }
}

export const useSpatialNavigation = () => {
  useEffect(() => {
    addScript(initSpatialNavigation)
  }, [])
}
