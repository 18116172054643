const mayaConfirmRestart = (state: any, action: any) => {
  switch (action.type) {
    case 'mayaOpenConfirmRestart':
      return { ...state, confirmRestartOpen: true }
    case 'mayaCloseConfirmRestart':
      return { ...state, confirmRestartOpen: false }
    default:
      return state
  }
}

export default mayaConfirmRestart
