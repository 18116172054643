import * as _ from 'lodash'

export enum THEMES {
  DEFAULT = 'DEFAULT',
  DARK = 'DARK',
  SEGUROS_BOLIVAR = 'SEGUROS_BOLIVAR'
}

const segurosBolivarColors = {
  text: '#000000',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#FFF',
  textTitle: '#016D38',

  primary: '#FFDC5D',
  primaryLight: '#88FFE1',
  primaryText: '#016D38',

  secondary: '#FFFFFF',
  secondaryLight: '#4bd6c4',
  secondaryText: '#016D38',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimary: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimarySolid: 'rgba(255, 255, 255)',
  backgroundSecondary: '#016D38',
  backgroundSecondaryHover: 'rgba(51, 51, 51, 0.3)',
  backgroundOverlay: 'rgb(51, 51, 51, 0.5)',
  error: '#BF2828',
  warning: '#FBBD1D',

  inputBackground: '#FFFFFF',
  inputBackgroundHover: '#FFFFFF',
  inputBorder: '#CCCCCC',

  btnPrimaryBg: '#FFDC5D',
  btnPrimaryBgHover: '#FFD333',

  btnWhiteInverted: '#016D38',
  btnWhiteInvertedHover: '#005833',

  btnSecondaryBg: '#F0F6F3',
  btnSecondaryBgHover: '#E6F0EB',

  optionSelectedBorder: '#016D38',
  optionBorder: '#CCCCCC',
  optionText: '#999999',
  optionSelectedBg: '#F0F6F3',
  optionSelectedText: '#333333',

  datePickerDayBg: '#FFDC5D',
  datePickerDayColor: '#000',
  datePickerOverlayBg: '#FFF',

  checkboxChecked: '#016D38',

  link: '#016D38',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#FFDC5D',
  timeoutTrailColor: '#FBFBFB',

  overlayBackground: 'rgba(0,0,0,0.7)'
}

const colors = {
  text: '#000000',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#000000',
  textTitle: '#000000',

  primary: '#275DAF',
  primaryLight: '#4287f5',
  primaryText: '#FFFFFF',

  secondary: '#22b5a1',
  secondaryLight: '#4bd6c4',
  secondaryText: '#FFFFFF',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimary: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimarySolid: 'rgba(255, 255, 255)',
  backgroundSecondary: 'rgba(51, 51, 51, 0.1)',
  backgroundSecondaryHover: 'rgba(51, 51, 51, 0.3)',
  backgroundOverlay: 'rgb(51, 51, 51, 0.5)',

  inputBackground: 'rgba(51, 51, 51, 0.1)',
  inputBackgroundHover: 'rgba(51, 51, 51, 0.1)',
  inputBorder: 'rgba(51, 51, 51, 0.1)',

  datePickerDayBg: 'rgba(51, 51, 51, 0.1)',
  datePickerDayColor: '#FFF',
  datePickerOverlayBg: 'rgba(255, 255, 255, 0.95)',

  optionSelectedBorder: '#000000',
  optionSelectedBg: 'transparent',
  optionBorder: 'rgba(51, 51, 51, 0.1)',

  checkboxChecked: '#275DAF',

  link: 'currentColor',

  error: '#BF2828',
  warning: '#FBBD1D',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#4287f5',
  timeoutTrailColor: '#BDC0C7',

  overlayBackground: 'rgba(0,0,0,0.7)'
}

const darkColors = {
  text: '#FFFFFF',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#FFFFFF',
  textTitle: '#FFFFFF',

  link: 'currentColor',

  primary: '#275DAF',
  primaryLight: '#4287f5',
  primaryText: '#FFFFFF',

  secondary: '#22b5a1',
  secondaryLight: '#4bd6c4',
  secondaryText: '#FFFFFF',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(51,51,51,0.95)',
  backgroundPrimary: 'rgba(51,51,51,0.95)',
  backgroundPrimarySolid: 'rgba(51,51,51)',
  backgroundSecondary: 'rgba(255, 255, 255, 0.1)',
  backgroundOverlay: 'rgb(255, 255, 255, 0.3)',

  backgroundSecondaryHover: 'rgba(255, 255, 255, 0.3)',

  inputBackground: 'rgba(255, 255, 255, 0.1)',
  inputBackgroundHover: 'rgba(255, 255, 255, 0.1)',
  inputBorder: 'rgba(255, 255, 255, 0.1)',

  optionSelectedBorder: '#FFFFFF',
  optionSelectedBg: 'transparent',
  optionBorder: 'rgba(255, 255, 255, 0.1)',

  datePickerDayBg: 'rgba(255, 255, 255, 0.1)',
  datePickerDayColor: '#FFF',
  datePickerOverlayBg: 'rgba(51,51,51,0.95)',

  checkboxChecked: '#275DAF',

  error: '#BF2828',
  warning: '#FBBD1D',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#4287f5',
  timeoutTrailColor: '#BDC0C7',

  overlayBackground: 'rgba(0,0,0,0.7)'
}

const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

const themesColors: Record<THEMES, Record<string, string>> = {
  [THEMES.DARK]: darkColors,
  [THEMES.DEFAULT]: colors,
  [THEMES.SEGUROS_BOLIVAR]: segurosBolivarColors
}

const baseTheme = (theme: THEMES, embeddedMode: boolean) => ({
  colors: {
    // ...(theme === THEMES.DARK ? darkColors : colors),
    ...themesColors[theme],

    // PTT animation
    pttMobileGlow: hex2rgba(colors.primaryLight, 0.2), // Mobile - glow around button while recording
    // PTT desktop colors & gradients
    pttColRecording: 'white',
    pttImgRecording: `radial-gradient(circle, ${colors.primaryLight} 0%, white 100%)`,
    pttColSending: 'primaryLight',
    pttImgSending: `radial-gradient(circle, white 0%,${colors.primaryLight} 100%)`,
    pttColWaiting: 'primaryLight',
    pttImgWaiting: 'primary'
  },
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'inherit'
  },
  fontSizes: ['14px', '15px', '16px', '20px', '22px', '24px', '30px'],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: '31px'
  },
  space: [
    0,
    4,
    8,
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    28,
    32,
    40,
    48,
    56,
    64,
    80,
    100,
    128
  ],
  borders: {
    focusOutline: 'none'
  },
  radii: {
    button: 2,
    buttonBig: 4,
    input: 1,
    card: 8
  },
  shadows: {
    card: '0 4px 4px rgba(0, 0, 0, 0.25)'
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
      pb: 2,
      mt: 3,
      mx: 4
    },
    title: {
      fontFamily: 'body',
      color: 'textTitle'
    },
    homeWelcome: {
      variant: 'text.title',
      fontSize: ['16px', '16px', '24px', '24px', '24px', '24px'],
      lineHeight: ['16px', '16px', '32px', '32px', '32px', '32px'],
      letterSpacing: '-0.01em'
    }
  },
  variants: {
    link: {
      color: 'primary'
    },
    questionContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    homeContainer: {
      flexDirection: 'column',
      alignSelf: 'flex-end',
      m: [0, 0, 0, 0, 6, 6],
      ml: [0, 0, 0, 0, 16, 16],
      backgroundColor: 'backgroundPrimary',
      borderRadius: ['unset', 'unset', 'unset', 'unset', '8px', '8px'],
      zIndex: 5,
      position: ['fixed', 'fixed', 'fixed', 'fixed', 'static', 'static'],
      bottom: [0, 0, 0, 0, 'unset', 'unset'],
      color: 'text',
      width: ['100%', '100%', '100%', '100%', 560, 560],
      p: [6, 6, 10, 10, 10, 10],
      backdropFilter: 'blur(50px)'
    },
    card: {
      bg: 'white',
      color: 'textLight',
      borderRadius: 'card',
      p: [6, 6, 12, 12, 12, 12],
      boxShadow: 'card'
    },
    modal: {
      variant: 'variants.card',
      label: 'modal',
      width: ['92%', '92%', 850],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    transcript: {
      label: 'Transcript',
      borderRadius: '3px',
      overflow: 'hidden',
      maxHeight: '99%',
      color: 'text',
      '& .transcript-header': {},
      '& .transcript-content': {
        fontSize: ['14px', '14px', '18px', '18px', '18px', '18px'],
        px: 5,
        pb: 5,
        height: ['100%', '100%', '100%', '100%', 'auto', 'auto']
      },
      '& .transcript-footer': {
        height: 45,
        position: ['fixed', 'fixed', 'fixed', 'fixed', 'static'],
        minHeight: '45px',
        bottom: [0, 0, 0, 0, 'auto', 'auto'],
        backgroundColor: 'white',
        width: '100%',
        p: 2
      }
    },
    inputSelect: {
      outline: 'none',
      border: 'none',
      width: 'max-content',
      '& option': {
        color: 'text',
        bg: 'background'
      },
      color: 'text',
      fontSize: 0,
      lineHeight: 'body',
      appearance: 'none',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      bg: 'background',
      fontFamily: 'inherit',
      ':focus': {
        outline: 'none'
      },
      pr: 10,
      py: 1,
      pl: 2
    },
    homeLogo: {
      backgroundColor: [
        'rgba(0,0,0,0.3)',
        'rgba(0,0,0,0.3)',
        'rgba(0,0,0,0.3)',
        'rgba(0,0,0,0.3)',
        'transparent',
        'transparent'
      ],
      maxWidth: ['unset', 'unset', 'unset', 'unset', '30vw', '30vw'],
      width: ['100%', '100%', '100%', '100%', 'auto', 'auto'],
      zIndex: '4',
      position: 'absolute',
      top: 0,
      left: [0, 0, 0, 0, '16px', '16px'],
      display: 'flex',
      justifyContent: [
        'center',
        'center',
        'center',
        'center',
        'flex-start',
        'flex-start'
      ],
      alignItems: [
        'center',
        'center',
        'center',
        'center',
        'flex-start',
        'flex-start'
      ],
      '& img': {
        mt: ['16px', '16px', '16px', '16px', '32px', '32px'],
        display: 'block',
        width: ['70%', '70%', '70%', '70%', 'auto', 'auto'],
        p: 2
      }
    },
    informationButtonsContainer: {
      maxHeight: ['20vh', '20vh', '20vh', '20vh', '80%', '80%'],
      overflowY: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      flex: 1,
      alignItems: 'center',
      mb: 2,
      '& > button': {
        mb: 2
      },
      '& > *:not(:last-child)': {
        mr: 2
      },
      '::-webkit-scrollbar': {
        bg: 'backgroundPrimary',
        height: '8px'
      },
      '::-webkit-scrollbar-thumb': {
        bg: 'text',
        borderRadius: '100px'
      },
      '::-webkit-scrollbar-track': {
        bg: 'backgroundPrimary'
      }
    }
  },
  buttons: {
    // TODO
    unstyled: {
      background: 'transparent',
      color: 'secondary',
      fontFamily: 'inherit',
      fontSize: 0
    },
    primary: {
      fontFamily: 'inherit',
      borderRadius: '4px',
      fontWeight: '600',
      fontSize: ['11px', '11px', '14px', '14px', '14px', '14px'],
      lineHeight: ['13px', '13px', '17px', '17px', '17px', '17px'],
      p: '8px',
      transition: 'all 0.4s',
      cursor: 'pointer',
      backgroundColor: 'primary',
      border: '1px solid transparent',
      borderColor: 'primary',
      whiteSpace: 'nowrap',
      '& svg, & img': {
        fill: 'currentColor'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'primaryText',
      '& img, & svg': {
        mr: 2
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'primaryLight'
        }
      },
      '&:focus': {
        outline: '1px solid white'
        // backgroundColor: 'primary',
        // '--box-shadow-color': 'primary',
        // boxShadow: '0 0 3px var(--box-shadow-color)',
        // borderColor: 'primaryLight'
      },
      '&:disabled': {
        opacity: 0.2
      }
    },
    primaryInverted: {
      variant: 'buttons.primary',
      bg: 'black',
      borderColor: 'primary',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(1, 99, 172, 0.6)'
        }
      },
      '&:focus': {
        outline: 'none',
        '--box-shadow-color': 'primary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        borderColor: 'grey',
        color: '#E3E5E8'
      }
    },
    secondaryInverted: {
      variant: 'buttons.secondary',
      color: 'secondary',
      background: 'white',
      borderColor: 'secondary',
      '& svg, & img': {
        // fill: 'currentColor'
        fill: 'red'
      },
      '& img, & svg': {
        // mr: 2
        mr: 20
        // TODO
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: '#2E2818'
        }
      },
      '&:focus': {
        borderColor: 'secondary',
        '--box-shadow-color': 'secondary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        borderColor: 'greyDark',
        color: '#85898E'
      }
    },
    outline: {
      color: 'textAlternate',
      fontWeight: '300',
      cursor: 'pointer',
      border: '1px solid #FFFFFF',
      transition: 'all 1s',
      backgroundColor: 'transparent',
      borderRadius: 50,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.1)'
        }
      },
      '&:focus': {
        outline: 'none',
        border: '1px solid transparent',
        borderColor: 'white',
        '--box-shadow-color': 'transparent',
        boxShadow: 'none'
      },
      '&:disabled': {
        backgroundColor: 'thirdGray',
        color: 'fourthGray'
      }
    },
    white: {
      fontFamily: 'inherit',
      borderRadius: 'button',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '-0.01em',
      p: '8px',
      transition: 'all 0.4s',
      cursor: 'pointer',
      backgroundColor: '#fff',
      border: '1px solid transparent',
      whiteSpace: 'nowrap',
      '& svg, & img': {
        fill: 'currentColor'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#333333',
      '& img, & svg': {
        mr: 2
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.7)'
        }
      },
      '&:focus': {
        backgroundColor: '#fff',
        '--box-shadow-color': 'primary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        opacity: 0.2
      }
    },
    whiteInverted: {
      fontFamily: 'inherit',
      borderRadius: '4px',
      fontWeight: 'normal',
      fontSize: ['11px', '11px', '14px', '14px', '14px', '14px'],
      lineHeight: ['13px', '13px', '17px', '17px', '17px', '17px'],
      letterSpacing: '-0.01em',
      p: '8px',
      transition: 'all 0.4s',
      cursor: 'pointer',
      bg: 'backgroundSecondary',
      border: '1px solid transparent',
      whiteSpace: 'nowrap',
      '& svg, & img': {
        fill: 'currentColor'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'text',
      '& img, & svg': {
        mr: 2
      },
      '@media (hover: hover)': {
        '&:hover': {
          bg: 'backgroundSecondaryHover'
        }
      },
      ':focus': {
        border: '1px solid white'
        // outline: '1px solid white'
        // bg: 'backgroundSecondary',
        // '--box-shadow-color': 'primary',
        // boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        opacity: 0.2
      }
    },
    secondary: {
      variant: 'buttons.whiteInverted'
    },
    select: {
      variant: 'buttons.unstyled',
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: 'backgroundSecondary',
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '16px',
      paddingRight: '16px',
      width: '100%',
      textAlign: 'left',
      color: 'text',
      cursor: 'pointer',
      whiteSpace: 'pre-wrap',
      transition: 'border .4s, color .4s, background .4s',
      ':focus': {
        outline: 'none'
      }
    },
    icon: {
      stroke: 'text',
      width: 'auto',
      p: 2,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      backgroundColor: 'backgroundSecondary',
      borderRadius: 'buttonBig',
      cursor: 'pointer',
      minWidth: 'unset',
      transition: 'all .2s',
      '& svg': {
        minWidth: 18,
        height: 18,
        margin: 0
      },
      '&:hover': {
        backgroundColor: 'backgroundSecondaryHover'
      }
    },
    information: {
      variant: 'buttons.whiteInverted',
      width: 'fit-content',
      py: 2,
      px: 7,
      ml: 0,
      minHeight: 37,
      fontWeight: 700,
      minWidth: 'auto'
    },
    informationImportant: {
      variant: 'buttons.primary',
      width: 'fit-content',
      py: 2,
      px: 7,
      ml: 0,
      minHeight: 37,
      fontWeight: 700,
      minWidth: 'auto'
    },
    alertPrimary: {
      variant: 'buttons.primary'
    },
    alertInverted: {
      variant: 'buttons.whiteInverted'
    },
    startChatbot: {
      variant: 'buttons.whiteInverted',
      fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
      lineHeight: ['17px', '17px', '19px', '19px', '19px', '19px'],
      py: [3, 2],
      px: [6, 5]
    }
  },
  breakpoints: embeddedMode
    ? ['1px', '2px', '3px', '4px', '5px', '6px']
    : ['320px', '600px', '768px', '1025px', '1280px', '1440px'],
  zIndices: {
    transcript: 5,
    themeSelector: 4
  }
})

const tvTheme = (type: THEMES, embeddedMode: boolean) => ({
  borders: {
    focusOutline: '1px solid white'
  },
  buttons: {
    startChatbot: {
      fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
      lineHeight: ['17px', '17px', '19px', '19px', '19px', '19px'],
      fontWeight: 'normal',
      py: [3, 2],
      px: [6, 5],
      '&:focus': {
        outline: '1px solid #fff'
      }
    },
    select: {
      ':focus': {
        outline: 'none',
        backgroundColor: 'transparent',
        border: '1px solid white'
      }
    }
  }
})

// TODO: Merge theme keys in another way
const segurosBolivarTheme = (type: THEMES, embeddedMode: boolean) => ({
  fonts: {
    body: "'Roboto Condensed', sans-serif",
    heading: 'inherit'
  },
  buttons: {
    primary: {
      borderRadius: '999px',
      fontWeight: 'bold',
      bg: 'btnPrimaryBg',
      '&:hover': {
        bg: 'btnPrimaryBgHover',
        borderColor: 'transparent'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(0, 0, 0, 0.16)',
        borderColor: 'transparent'
      },
      '&:focus': {
        borderColor: '#DDCC85'
      },
      '&:disabled': {
        bg: '#EEEEEE',
        color: '#999999'
      }
    },
    whiteInverted: {
      fontWeight: 'bold',
      bg: 'white',
      borderRadius: '100px',
      border: '1px solid',
      color: 'btnWhiteInverted',
      borderColor: 'btnWhiteInverted',
      '&:hover': {
        color: 'btnWhiteInvertedHover',
        borderColor: 'btnWhiteInvertedHover',
        bg: 'white'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(11, 87, 30, 0.25)'
      },
      '&:focus': {
        bg: 'white'
      },
      '&:disabled': {
        bg: '#EEEEEE',
        color: '#999999'
      }
    },
    secondary: {
      bg: 'btnSecondaryBg',
      color: 'primaryText',
      border: '1px solid',
      borderColor: 'transparent',
      '&:hover': {
        bg: 'btnSecondaryBgHover'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(11, 87, 30, 0.25)'
      },
      '&:focus': {
        bg: 'btnSecondaryBg',
        borderColor: '#ADE4C9'
      },
      '&:disabled': {
        borderColor: '#999999',
        color: '#999999'
      }
    },
    select: {
      border: '1px solid',
      borderColor: 'optionBorder',
      borderRadius: '5px',
      color: 'optionSelectedText',
      '&:hover': {
        color: 'optionSelectedText',
        borderColor: [
          'optionBorder',
          'optionBorder',
          'optionBorder',
          'optionBorder',
          'optionText',
          'optionText'
        ]
      },
      '&:active': {
        borderColor: 'optionSelectedBorder',
        color: 'optionSelectedText',
        bg: 'optionSelectedBg'
      }
    },
    icon: {
      borderRadius: '100px',
      bg: 'btnSecondaryBg',
      color: 'primaryText',
      border: '1px solid',
      borderColor: 'transparent',
      '&:hover': {
        bg: 'btnSecondaryBgHover'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(11, 87, 30, 0.25)'
      },
      '&:focus': {
        bg: 'btnSecondaryBg',
        borderColor: '#ADE4C9'
      },
      '&:disabled': {
        borderColor: '#999999',
        color: '#999999'
      }
    },
    information: {
      flex: [1, 1, 1, 1, 'unset'],
      px: [7, 7, 7, 7, 14]
    },
    informationImportant: {
      flex: [1, 1, 1, 1, 'unset'],
      px: [7, 7, 7, 7, 14]
    },
    alertPrimary: {
      variant: 'buttons.primary',
      px: [10, 10, 10, 10, 14],
      py: [3, 3, 3, 3, 3, 3],
      fontSize: '14px',
      lineHeight: '20px',
      flex: [1, 1, 1, 1, 'unset', 'unset']
    },
    alertInverted: {
      variant: 'buttons.whiteInverted',
      px: [10, 10, 10, 10, 14],
      py: [3, 3, 3, 3, 3, 3],
      fontSize: '14px',
      lineHeight: '20px',
      flex: [1, 1, 1, 1, 'unset', 'unset']
    },
    startChatbot: {
      variant: 'buttons.primary',
      margin: '0 auto',
      px: [10, 10, 10, 10, 14, 14]
    }
  },
  variants: {
    questionContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    homeTerms: {
      display: ['none', 'none', 'none', 'none', 'none']
    },
    transcriptContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    inputSelect: {
      border: '1px solid #CCCCCC',
      pl: 6,
      pr: 10,
      py: 3,
      borderRadius: '4px'
    },
    homeLogo: {
      backgroundColor: [
        'transparent',
        'transparent',
        'transparent',
        'transparent',
        'transparent',
        'transparent'
      ],
      maxWidth: ['unset', 'unset', 'unset', 'unset', '20vw', '20vw'],
      width: ['60%', '60%', '60%', '60%', 'auto', 'auto'],
      zIndex: '4',
      position: 'absolute',
      top: 0,
      left: ['16px', '16px', '16px', '16px', '24px', '24px'],
      display: 'flex',
      justifyContent: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start'
      ],
      alignItems: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start'
      ],
      '& img': {
        mt: ['0px', '0px', '0px', '0px', '0px', '0px'],
        display: 'block',
        width: ['70%', '70%', '50%', '50%', 'auto', 'auto'],
        p: 2
      }
    },
    progressContainer: {
      '& div': { height: '14px' },
      '& > div': {
        border: '1px solid #CCCCCC',
        borderRadius: '100px',
        overflow: 'hidden'
      }
    },
    footerDisclaimer: {
      height: '48px',
      position: 'relative',
      display: ['block', 'block', 'block', 'block', 'none', 'none'],
      '& > img': {
        position: 'absolute',
        mixBlendMode: 'difference',
        transform: 'rotate(90deg) translate(50%, -50%)',
        transformOrigin: 'top',
        top: '24px',
        left: '50%',
        height: '200px',
        opacity: '0.5'
      }
    },
    informationButtonsContainer: {
      justifyContent: 'center'
    },
    homeContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
      mb: ['0', '0', '0', '0', '112px', '112px']
    }
  },
  text: {
    title: {
      fontWeight: 700
    },
    homeWelcome: {
      '& > span:nth-child(1)': {
        fontWeight: 'bold'
      },
      '& > span:nth-child(2)': {
        mt: [2, 2, 2, 2, 6, 6],
        fontSize: [0, 0, 2, 2, 2, 2],
        color: 'text',
        display: 'block',
        fontWeight: 'normal'
      }
    }
  }
})

const theme = (type: THEMES, embeddedMode: boolean) => {
  if (type === THEMES.SEGUROS_BOLIVAR) {
    const newTheme = _.merge(
      baseTheme(type, embeddedMode),
      segurosBolivarTheme(type, embeddedMode)
    )

    return newTheme
  }

  if (process.env.REACT_APP_BUILD_MODE === 'tv') {
    const newTheme = _.merge(
      baseTheme(type, embeddedMode),
      tvTheme(type, embeddedMode)
    )

    return newTheme
  }

  return baseTheme(type, embeddedMode)
}

export default theme
