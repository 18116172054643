import React from 'react'
import { Text, Flex } from 'rebass'
import styles from './styles'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import { TranscriptItem } from './Transcript'
import { stripSsml } from 'uneeq-react-core'
import { ReactComponent as UndoIcon } from './assets/undo.svg'

interface TranscriptMessageProps {
  item: TranscriptItem
  showUndo: boolean
}
const TranscriptMessage: React.FC<TranscriptMessageProps> = ({
  item,
  showUndo
}) => {
  const sendMessage = useSendSocketMessage()
  switch (item.type) {
    case 'question':
      return (
        <Flex sx={styles.question}>
          {showUndo && (
            <UndoIcon
              onClick={() => sendMessage({ type: 'back', id: item.id })}
            />
          )}
          <Text sx={{ ...styles.message, ...styles.avatarMessage }}>
            {stripSsml(item.question)}
          </Text>
        </Flex>
      )
    case 'response':
      return (
        <Text sx={{ ...styles.message, ...styles.userMessage }}>
          {item.label || item.response}
        </Text>
      )
    case 'infoPage':
      return null
    default:
      return null
  }
}

export default TranscriptMessage
