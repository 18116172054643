import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useChangeLanguage } from '../app/hooks/useChangeLanguage'
import { useDomainConfigContext } from '../app/hooks/useDomainConfigContext'
import { isBolivarSegurosPage } from '../bolivar-seguros'
import { LanguageInformation } from '../customPlugins/LanguageSelector/LanguageInformation'
import { LanguageOption } from '../customPlugins/LanguageSelector/LanguageOption'
import { getToken, setLanguageOnLocalStorage } from '../socket'
import { THEMES } from '../theme'

type ReponsibilityDisclaimer = {
  responsibilityDisclaimerText: string
  responsibilityDisclaimerImage: string
}

type UseApplicationStateProps = { embeddedApp?: boolean }

type UseApplicationStateHook = (
  args: UseApplicationStateProps
) => {
  headingMessage: string
  logo: string
  disableDH: boolean
  initError: string
  currentLanguage: string
  supportedLanguages: LanguageOption[]
  personaId: string
  session: string | null
  disclaimer: string | null
  bgImage?: string
  bgMobileImage?: string
  responsibilityDisclaimer: ReponsibilityDisclaimer | null
  bgChatImage?: string
  shouldAcceptTerms: boolean
  activeTheme: THEMES
  hideTranscript: boolean
  loading: boolean
}

export const useApplicationState: UseApplicationStateHook = ({
  embeddedApp
}) => {
  const { i18n } = useTranslation()
  const { dispatch: domainConfigDispatcher } = useDomainConfigContext()
  // For faster testing, skip straight to digitalHuman
  const [headingMessage, setHeadingMessage] = useState('')
  const [logo, setLogo] = useState('')
  const [loading, setLoading] = useState(true)
  const [disableDH, setDisableDH] = useState(true)
  const [initError, setInitError] = useState('')
  const [session, setSession] = useState('')
  const [currentLanguage, setCurrentLanguage] = useState('')
  const [disclaimer, setDisclaimer] = useState(null)
  const [supportedLanguages, setSupportedLanguages] = useState<
    LanguageOption[]
  >([])
  const [personaId, setPersonaId] = useState(
    process.env.REACT_APP_UNEEQ_PERSONA_ID || ''
  )
  const [bgImage, setBgImage] = useState(undefined)
  const [bgMobileImage, setBgMobileImage] = useState(undefined)
  const [
    responsibilityDisclaimer,
    setResponsibilityDisclaimer
  ] = useState<ReponsibilityDisclaimer | null>(null)
  const [bgChatImage, setBgChatImage] = useState(undefined)
  const [shouldAcceptTerms, setShouldAcceptTerms] = useState(false)
  const [activeTheme, setActiveTheme] = useState(
    isBolivarSegurosPage() ? THEMES.SEGUROS_BOLIVAR : THEMES.DARK
  )
  const [hideTranscript, setHideTranscript] = useState(false)

  const setNewLanguage = useCallback(
    (lang: LanguageInformation) => {
      // add language bundle and overrides current one if already added
      i18n.addResourceBundle(
        transformLanguageToISO(lang.key),
        'translation',
        lang.translation,
        true,
        true
      )
      setCurrentLanguage(transformLanguageToISO(lang.key))
      setPersonaId(lang.personaId)
      setLanguageOnLocalStorage(lang.key)
    },
    [i18n]
  )

  useChangeLanguage(setNewLanguage)

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage)
    }
  }, [currentLanguage, i18n])

  useEffect(() => {
    getToken()
      .then(
        ({
          message,
          disableDigitalHuman,
          logo,
          title,
          i18n: i18nProps,
          supportedLanguages,
          session,
          disclaimer,
          bgImage,
          bgMobileImage,
          bgChatImage,
          hideTranscript,
          responsibilityDisclaimerImage,
          responsibilityDisclaimerText,
          showLogoOnFlow,
          hideInfoActions,
          shouldAcceptTerms,
          favicon,
          theme
        }: any) => {
          setLoading(false)
          const headingMessage = message || ' '
          setHeadingMessage(headingMessage)
          setDisableDH(disableDigitalHuman)
          setLogo(logo)

          setBgImage(bgImage)
          setBgMobileImage(bgMobileImage)
          setBgChatImage(bgChatImage)
          setHideTranscript(hideTranscript)
          setResponsibilityDisclaimer({
            responsibilityDisclaimerText,
            responsibilityDisclaimerImage
          })
          setShouldAcceptTerms(shouldAcceptTerms)
          setActiveTheme(
            Object.values(THEMES).includes(theme) ? theme : THEMES.DARK
          )

          if (domainConfigDispatcher) {
            domainConfigDispatcher({
              type: 'SET_INITIAL_CONFIG',
              payload: {
                disclaimerImage: responsibilityDisclaimerImage || '',
                logo,
                showLogoOnFlow,
                hideInfoActions
              }
            })
          }

          if (title && !embeddedApp) {
            document.title = title
          }

          if (disclaimer) {
            setDisclaimer(disclaimer)
          }

          if (i18nProps) {
            setNewLanguage(i18nProps)
          }

          if (favicon) {
            const link = document.querySelector<HTMLLinkElement>(
              'link[rel="icon"]'
            )
            if (link) {
              link.href = favicon
            }
          }

          setSupportedLanguages(
            supportedLanguages.map((lang: any) => ({
              ...lang,
              code: transformLanguageToISO(lang.code)
            }))
          )

          if (session) {
            setSession(session)
          }
        }
      )
      .catch(({ message }: any) => {
        setInitError(message)
        setLoading(false)
      })
  }, [embeddedApp, setNewLanguage, domainConfigDispatcher])

  return {
    headingMessage,
    logo,
    disableDH,
    initError,
    currentLanguage,
    supportedLanguages,
    personaId,
    session,
    disclaimer,
    bgImage,
    bgMobileImage,
    responsibilityDisclaimer,
    bgChatImage,
    shouldAcceptTerms,
    activeTheme,
    hideTranscript,
    loading
  }
}

export const transformLanguageToISO = (lang: string) => {
  if (lang && !lang.includes('-')) return lang
  const langSplit = (lang.split('-') as unknown) as string[]
  const location = langSplit[1].toUpperCase()
  return `${langSplit[0]}-${location}`
}
