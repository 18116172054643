import React, { useContext, useState, useMemo, useEffect } from 'react'
import {
  stripSsml,
  UneeqContext,
  useUneeqState,
  useIsSmallScreen
} from 'uneeq-react-core'
import { useTranslation } from 'react-i18next'
import { useSendSocketMessage } from '../../../app/hooks/useSendSocketMessage'
import { Button, Flex, Text } from 'rebass'
import styles from '../styles'
import { Textarea } from '@rebass/forms'
import { markdownQuestion } from '../Question'

const TextareaQuestion = () => {
  const { dispatch } = useContext(UneeqContext)
  const sendMessage = useSendSocketMessage()
  const { t } = useTranslation()
  const { mayaQuestion, hideQuestionTitle } = useUneeqState()

  const isSmallScreen = useIsSmallScreen()
  const [text, setText] = useState('')
  const questionText = useMemo(
    () => markdownQuestion(stripSsml(mayaQuestion.question)),
    [mayaQuestion]
  )

  useEffect(() => {
    if (mayaQuestion.value) {
      setText(mayaQuestion.value)
    }
  }, [mayaQuestion])

  const submitTextInput = (text: string) => {
    const info = {
      type: 'response',
      questionId: mayaQuestion.id,
      response: text,
      label: text ? text : t('Transcript.skippedQuestion')
    }
    dispatch({ type: 'mayaMessage', payload: info })
    sendMessage(info)
    setText('')
  }

  const disabledSubmitButton = !text

  const submitButton = () => {
    if (!mayaQuestion.optional || !disabledSubmitButton) {
      return (
        <Button
          id="submit"
          type="submit"
          disabled={disabledSubmitButton}
          onClick={() => submitTextInput(text.toString())}
          sx={{ ...styles.button, ...styles.textAreaButton }}
        >
          {t('Question.submit')}
        </Button>
      )
    }

    return (
      <Button
        type="submit"
        onClick={() => submitTextInput('')}
        sx={{ ...styles.button, ...styles.textAreaButton }}
      >
        {t('Question.skip')}
      </Button>
    )
  }

  return (
    <Flex sx={styles.questionContainer}>
      <Flex sx={styles.topContainer}>
        {!hideQuestionTitle && <Text sx={styles.question}>{questionText}</Text>}
        <Text sx={styles.instructions}>{mayaQuestion.instruction}</Text>
      </Flex>
      <Flex
        sx={{
          ...styles.inputContainer
        }}
      >
        <Textarea
          value={text}
          onChange={e => setText(e.target.value)}
          autoFocus={!isSmallScreen}
          placeholder={
            mayaQuestion.placeholder || t('Question.inputPlaceholder')
          }
          sx={{ ...styles.textInput, resize: 'none' }}
          rows={isSmallScreen ? 3 : 5}
        />
        {submitButton()}
      </Flex>
    </Flex>
  )
}

export default React.memo(TextareaQuestion)
