import { ApiResponse } from './types/ApiResponse'
import { Todo } from './types/Todo'
import { User } from './types/User'
import { b64toBlob } from '../utils'

const BASE_URL = process.env.REACT_APP_TV_API_URL!

const ENDPOINTS_ID = {
  LOGIN: '_authenticateMayaPatient',
  FIND: '_find',
  BATCH_SAVE: '_batchSave',
  CREATE_ALGO_NOTE: 'createAlgorithmNote'
}

const QUERIES = {
  TODO_LIST: 'patientTodoList'
}

type LoginResponse = {
  token: string
  user: User
}

export const login = async (
  phone: string,
  password: string
): Promise<ApiResponse<LoginResponse>> => {
  const body = {
    id: ENDPOINTS_ID.LOGIN,
    paramValue: {
      mobile: phone,
      password
    }
  }

  return await makeRequest(body)
}

export const getPendingTodoList = async (
  patientId: string,
  token: string
): Promise<ApiResponse<Todo[]>> => {
  const body = {
    id: ENDPOINTS_ID.FIND,
    paramValue: {
      query: {
        id: QUERIES.TODO_LIST,
        addOnFilter: {
          patient: {
            _id: patientId
          },
          status: 'pending'
        },
        sort: {
          _id: -1
        },
        limit: 100,
        skip: 0
      },
      model: 'PatientTodo'
    },
    token
  }

  return await makeRequest(body)
}

export const getCompletedTodoList = async (
  patientId: string,
  token: string
) => {
  const body = {
    id: ENDPOINTS_ID.FIND,
    paramValue: {
      query: {
        id: QUERIES.TODO_LIST,
        addOnFilter: {
          patient: {
            _id: patientId
          },
          status: 'completed'
        },
        sort: {
          _completedOn: -1
        },
        limit: 100,
        skip: 0
      },
      model: 'PatientTodo'
    },
    token
  }

  return await makeRequest(body)
}

export const sendWellnessPatientNote = async (
  pdfData: any,
  sessionId: string,
  patientId: string
) => {
  const pdfBlob = b64toBlob(pdfData.base64, 'application/pdf')
  const formData = new FormData()
  formData.append('algo_type', 'Daily Check In')
  formData.append('sid', sessionId)
  formData.append('patientId', patientId)
  formData.append('flag', 'true')
  formData.append('pdf', pdfBlob, pdfData.name)

  const token = '16af011d-5aef-4a0c-b9e0-646207bc7db9' // hardcoded for now
  return makeFormDataRequest(formData, '/link_algonote', token)
}

export const completeTodo = async (id: string, token: string) => {
  const body = {
    id: ENDPOINTS_ID.BATCH_SAVE,
    paramValue: {
      updates: [
        {
          updates: {
            update: {
              _id: id,
              changes: {
                _completedOn: Date.now(),
                status: 'completed'
              }
            }
          }
        }
      ],
      model: 'PatientTodo'
    },
    token
  }

  return await makeRequest(body)
}

export const uncompleteTodo = async (id: string, token: string) => {
  const body = {
    id: ENDPOINTS_ID.BATCH_SAVE,
    paramValue: {
      updates: [
        {
          updates: {
            update: {
              _id: id,
              changes: {
                _completedOn: null,
                status: 'pending'
              }
            }
          }
        }
      ],
      model: 'PatientTodo'
    },
    token
  }

  return await makeRequest(body)
}
const makeRequest = async (body: any, endpoint = '/invoke') => {
  const response = await fetch(`${BASE_URL}${endpoint}`, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })

  return response.json()
}

const makeFormDataRequest = async (
  formData: any,
  endpoint = '/invoke',
  token: String
) => {
  const response = await fetch(`${BASE_URL}${endpoint}`, {
    body: formData,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response.json()
}
