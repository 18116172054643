import React, { useContext } from 'react'
// @ts-ignore
import { Box, Button, Flex, Heading, Text } from 'rebass'
import styles from './styles'
import {
  trackHandler,
  useUneeqState,
  UneeqContext,
  useDisableBodyOverflow
} from 'uneeq-react-core'
import { MayaOverlay } from '../MayaOverlay'
import { useTranslation } from 'react-i18next'
import { ReactComponent as QuestionMarkIcon } from '../../app/assets/icons/questionMark.svg'
interface MayaConfirmLeaveProps {
  onConfirm: () => any
}

const MayaConfirmLeave = React.memo(({ onConfirm }: MayaConfirmLeaveProps) => {
  const { confirmLeaveOpen } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()

  useDisableBodyOverflow(confirmLeaveOpen)

  const closeConfirmLeave = () => dispatch({ type: 'mayaCloseConfirmLeave' })
  const handleConfirm = () => {
    onConfirm()
    closeConfirmLeave()
  }

  if (!confirmLeaveOpen) return null

  return (
    <MayaOverlay>
      <Flex sx={styles.container}>
        <Box sx={{ pt: 8, pb: 12 }}>
          <QuestionMarkIcon />
        </Box>
        <Heading variant="title" sx={styles.heading}>
          {t('LeaveSession.title')}
        </Heading>
        <Text sx={styles.text}>{t('LeaveSession.subtitle')}</Text>
        <Flex sx={styles.buttonsContainer}>
          <Button
            id="confirm"
            variant="alertInverted"
            onClick={trackHandler(handleConfirm, 'exit-session-btn')}
          >
            {t('LeaveSession.confirm')}
          </Button>
          <Button
            id="cancel"
            variant="alertPrimary"
            sx={styles.backToChatButton}
            onClick={closeConfirmLeave}
          >
            {t('LeaveSession.dismiss')}
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
})

export default MayaConfirmLeave
