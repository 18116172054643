import React, { useContext } from 'react'
// @ts-ignore
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import { Button, Flex, Heading, Text, Box } from 'rebass'
import { trackHandler, UneeqContext, useTimeout } from 'uneeq-react-core'
import { Overlay } from 'uneeq-react-ui'
import styles from './styles'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'
import { MayaOverlay } from '../MayaOverlay'
import { ReactComponent as QuestionMarkIcon } from '../../app/assets/icons/questionMark.svg'

const MayaTimeout = ({ endSession }: any) => {
  const theme: any = useTheme()
  const {
    config: { timeoutWarning }
  } = useContext(UneeqContext)
  const { timeoutOpen, timeLeft, resetTimeout } = useTimeout()
  const { t } = useTranslation()

  if (!timeoutOpen) return null

  const seconds = Math.max(Math.ceil(timeLeft / 1000), 0)
  const progress = 100 - (timeLeft / timeoutWarning) * 100

  return (
    <MayaOverlay>
      <Flex sx={styles.container}>
        <Box sx={{ pt: 8, pb: 12 }}>
          <QuestionMarkIcon />
        </Box>
        <Heading variant="title" sx={styles.heading}>
          {t('Timeout.heading')}
        </Heading>
        <Text sx={styles.text}>{t('Timeout.subtitle')}</Text>
        <Flex sx={styles.progress}>
          <Text sx={styles.time}>{seconds}s</Text>
          <Box variant="progressContainer">
            <Progress
              percent={progress}
              theme={{
                active: {
                  trailColor: theme.colors.timeoutTrailColor,
                  color: theme.colors.timeoutColor
                }
              }}
            />
          </Box>
        </Flex>
        <Flex sx={styles.buttonsContainer}>
          <Button
            id="leave-chat"
            variant="alertInverted"
            sx={styles.leaveChatButton}
            onClick={trackHandler(endSession, 'timeout-finished-btn')}
          >
            {t('Timeout.leaveChat')}
          </Button>
          <Button
            id="back-chat"
            variant="alertPrimary"
            sx={styles.backToChatButton}
            onClick={trackHandler(resetTimeout, 'timeout-continue-session-btn')}
          >
            {t('Timeout.backToChat')}
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
}

export default MayaTimeout
